import React from 'react'
import { get } from 'lodash'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { open as OpenChat } from '@saatva-bits/pattern-library.utils.chat'

import styles from './SupportValueProps.module.scss'

const phoneNumbers = {
    'standard': '1-877-672-2882'
}

const urls = {
    'quiz': '/mattress-quiz'
}

/**
 * 
 * @param {'standard' | 'supportProps'} configKey 
 * @returns 
 */
const SupportValuePropsConfig = (configKey = 'standard') => {
    /* 
    TODO: Modify icon-chat-bubble and icon-quiz svg files in the Assets repo to match other sprite svg formats, such viewbox, paths, stroke-widths, etc.
        - Both were migrated directly from Coresite-Node's SvgChat and SvgQuiz, respectively, without modification.
        - Update scss styles to align with updated sprite formats.
        - icon-phone was already transferred to the sprite format and does not need any modification in the assets repo.
    */
    const standardProps = {
        title: 'Need help deciding?',
        items: [
            {
                text: 'Chat with us',
                component: <SvgSprite spriteID='icon-chat' />,
                action: { onClick: OpenChat }
            },
            {
                text: 'Give us a ring',
                subheading: <span className={styles.subheading}>{phoneNumbers.standard}</span>,
                component: <SvgSprite spriteID='icon-phone' />,
                action: { href: `tel:+${phoneNumbers.standard}` }
            },
            {
                text: 'Take our mattress quiz',
                component: <SvgSprite spriteID='icon-quiz' />,
                action: { href: urls.quiz }
            }
        ]
    }

    const supportProps = {
        title: 'Need help with your order? We’re here to help 24/7.',
        items: [
            {
                text: 'Chat with us',
                component: <SvgSprite spriteID='icon-chat' />,
                action: { onClick: OpenChat }
            },
            {
                text: 'Call us',
                subheading: <span className={styles.subheading}>{phoneNumbers.standard}</span>,
                component: <SvgSprite spriteID='icon-phone' />,
                action: { href: `tel:+${phoneNumbers.standard}` }
            },
            {
                text: 'Email us',
                component: <SvgSprite spriteID='icon-headphones' />,
                action: { href: 'mailto:info@saatvamattress.com' }
            }
        ]
    }

    /*
        - If different configuration are needed in the future they can be added below with the configKey set to the productCode.
        - Standard props will display by default in the absence of a configKey being passed in.
        - Currently only mattress pdps EXCEPT for the dog-bed contain support value props. 
    */
    const config = {
        'standard': standardProps,
        'supportProps': supportProps
    }

    return get(config, configKey, ['standard'])
}

export default SupportValuePropsConfig
