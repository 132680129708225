import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Badge } from '@saatva-bits/pattern-library.components.badge'
import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'

import styles from './ProductImage.module.scss'

/**
 * Taken from detail modal module to not bump packages there
 *
 * The tweak here is for implementing the gallery easier and also for
 * allowing to choose if the image reacts to the buystack swatch selections or not
 * @param {object} props
 * @param {string} props.alt - Alt if image doesnt show up
 * @param {string} props.tag - Tag string text
 * @param {string} props.filename - filename path
 * @param {string} props.folder - folder path
 * @param {string} props.dataSelector - imgix domain url to retrieve image
 * @param {import('react').ReactNode} props.favoriteIcon - Favorite icon button react component
 * @param {string} props.imgixDomain - imgix domain url to retrieve image
 * @param {object} props.styleClasses - groups together override classes
 * @param {string} props.styleClasses.containerClasses
 * @param {string} props.styleClasses.imageClasses
 * @param {string} props.styleClasses.pictureClasses
 */
const ProductImage = ({
    alt,
    tag,
    filename,
    folder,
    dataSelector,
    favoriteIcon,
    styleClasses = {},
    imgixDomain = 'saatva-qa.imgix.net',
}) => {
    const { containerClasses, pictureClasses, imageClasses } = styleClasses

    const containerClassnames = classNames(styles.imageContainer, containerClasses)
    const pictureClassnames = classNames(styles.imagePicture, pictureClasses)
    const imageClassnames = classNames(styles.image, imageClasses)

    return (
        <div className={containerClassnames}>
            <Picture
                lazyLoad
                alt={alt}
                name={filename}
                folder={folder}
                imgixDomain={imgixDomain}
                dataSelector={dataSelector}
                imageClassName={imageClassnames}
                className={pictureClassnames}
                prefixOverride={{
                    mobile: 'none',
                    tablet: 'none',
                    desktop: 'none'
                }}
            />
            {favoriteIcon && (
                <div className={styles.favoriteContainer}>
                    {favoriteIcon}
                </div>
            )}
            {tag && (
                <Badge text={tag} />
            )}
        </div>
    )
}

ProductImage.propTypes = {
    imgixDomain: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    folder: PropTypes.string.isRequired,
    tag: PropTypes.string,
    alt: PropTypes.string,
    styleClasses: PropTypes.shape({
        containerClasses: PropTypes.string,
        pictureClasses: PropTypes.string,
        imageClasses: PropTypes.string
    }),
    favoriteIcon: PropTypes.node
}

export default ProductImage