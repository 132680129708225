import classNames from 'classnames'
import PropTypes from 'prop-types'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { usePromotionsData, DiscountLabel } from '@saatva-bits/pattern-library.modules.promotions'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

import PriceRange from '@/components/PriceRange'
import { usePageProps } from '@/hooks/usePageProps'
import CATEGORIES from '@/temp-configs/category-constants'

import styles from './ProductSummary.module.scss'

const PriceStrikeThrough = ({ lowestPrice, lowestPriceDiscount, className, discountClassName }) => {
    const priceContent = formatCurrency(lowestPrice)
    const hasDiscount = lowestPrice !== lowestPriceDiscount
    const discountContent = hasDiscount && formatCurrency(lowestPriceDiscount)
    const mainPrice = hasDiscount ? discountContent : priceContent

    return (
        <div className={classNames(styles.price, className)}>
            {mainPrice}
            {discountContent && (
                <span className={classNames(styles.strikeThrough, discountClassName)}>
                    {priceContent}
                </span>
            )}
        </div>
    )
}

PriceStrikeThrough.propTypes = {
    className: PropTypes.string,
    discountClassName: PropTypes.string,
    lowestPrice: PropTypes.number.isRequired,
    lowestPriceDiscount: PropTypes.number.isRequired
}

const ProductSummary = ({
    title,
    subtitle,
    product,
    promotionDeps,
    selectionDeps,
    // selectedSizes = []
}) => {
    const { useGetDiscounts } = promotionDeps
    const {
        useProductState
        // useAttributeOptions
    } = selectionDeps
    const { getDiscounts } = useGetDiscounts()
    const { products, saleData } = usePageProps()
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent, theme } = sitewidePromo

    const { price, sku, variantUrl, bundledVariants, category } = useProductState(product.productCode, ['price', 'sku', 'variantUrl', 'bundledVariants', 'category'])

    const { isV1: isPromotionsMessagingEnabled } = useExperiment('EXP.PROMOTIONS_MSGING.EX-450')

    const isBundleCategory = category.toLowerCase() === CATEGORIES.BUNDLES

    let productsForDiscount = [{
        sku,
        parentSku: product.productCode,
        quantity: 1,
        price
    }]
    

    if (isBundleCategory) {
        let fullProduct = null
        if (saleData && saleData.bundles) {
            fullProduct = saleData.bundles.products.find(parent => parent.productCode === product.productCode)
        } else {
            fullProduct = products.find(parent => parent.productCode === product.productCode)
        }

        if (fullProduct.bundledProducts && fullProduct.bundledProducts.length > 0) {
            productsForDiscount = bundledVariants.map(bundledVariant => {
                const bundleProduct = fullProduct.bundledProducts.find(product => product.productCode === bundledVariant.productCode)
                const actualVariant = bundleProduct.variants.find(variant => variant.sku === bundledVariant.sku)

                return {
                    sku: bundledVariant.sku,
                    parentSku: bundledVariant.productCode,
                    price: actualVariant.price,
                    quantity: bundledVariant.quantity
                }
            })
        }
    }

    let { fullPrice, finalPrice, labelBreakdown } = getDiscounts(productsForDiscount)

    // temporarily handling bundle discounts manually
    if (isBundleCategory && enableBundleDiscounts && bundleDiscountPercent) {
        const bundleDiscount = bundleDiscountPercent / 100
        finalPrice = Math.round(fullPrice - (fullPrice * bundleDiscount))
        fullPrice = Math.round(fullPrice)
    }

    // Determine if we should show price/discounts for selected variant (used in modals with add to cart)
    // or show price range/discounts for all matching variants
    const useSelectedProductPrice = product.hasAddToCartCta || isBundleCategory

    // For selected variant price -> use labelBreakdown (discounts for current variant)
    // For price range -> use product.discountLabels (discounts that apply to all variants matching the filters)
    const discountLabelBreakdown = useSelectedProductPrice ? labelBreakdown : product.discountLabels

    const labels = isPromotionsMessagingEnabled && discountLabelBreakdown && discountLabelBreakdown.length > 0 ? discountLabelBreakdown.map((label) => {
        return {
            text: label.label,
            discountType: label.discountType,
            theme
        }
    }) : null

    return (
        <section className={styles.container}>
            <h2 className={`t-heading1 t-color ${styles.title}`}>
                <a href={variantUrl}>{title}</a>
            </h2>
            <div className={styles.subtitle}>
                {subtitle}
            </div>
            {useSelectedProductPrice ? <PriceStrikeThrough
                lowestPrice={fullPrice}
                lowestPriceDiscount={finalPrice}
                discountClassName={styles.strikeThrough}
            /> : <PriceRange containerClassName={styles.priceRange} {...product.priceRange} />}
            {labels && labels.length > 0 && (
                <div className={styles.discountLabelContainer}>
                    {labels.map((label) => <DiscountLabel {...label} key={label.text}/>)}
                </div>
            )}
        </section>
    )
}

ProductSummary.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    selectedSizes: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
    })),
    /** Interface for promo context dependencies */
    promotionDeps: PropTypes.shape({
        useGetDiscounts: PropTypes.func,
        applyDiscounts: PropTypes.func,
    }),
    /** Interface for selection context dependencies */
    selectionDeps: PropTypes.shape({
        useProductState: PropTypes.func,
        useAttributeOptions: PropTypes.func,
    }),
    product: PropTypes.shape({
        productCode: PropTypes.string,
        lowestPrice: PropTypes.number,
        highestPrice: PropTypes.number,
        configurableAttributes: PropTypes.arrayOf(PropTypes.string),
        priceRange: PropTypes.shape({
            fullPriceMin: PropTypes.number,
            fullPriceMax: PropTypes.number,
            finalPriceMin: PropTypes.number,
            finalPriceMax: PropTypes.number,
        }),
        variants: PropTypes.arrayOf(
            PropTypes.shape({
                sku: PropTypes.string,
                productCode: PropTypes.string,
                category: PropTypes.string,
                price: PropTypes.number,
            })
        )
    })
}

export default ProductSummary
