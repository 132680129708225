import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FavoriteIcon } from '@saatva-bits/pattern-library.modules.favorite'

import { pushDataLayerEvent } from '@/utils/datalayer'
import { getDetailModalArdadImages, getDetailTileArdadImages } from '@/utils/product'

import ProductImage from '../ProductImage'

import styles from './Gallery.module.scss'

import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import useIsMobile from '@/hooks/useIsMobile'

const MobileGallery = ({
    images,
    thumbnails,
}) => {
    const [selectedImageId, setSelectedImageId] = useState(images?.[0]?.id)

    const switchImage = (imageId) => {
        setSelectedImageId(imageId)
    }

    const mobileContainerClasses = classNames(styles.mobileContainer, 'u-hidden--lg-up')
    const selectedImageProps = images.find(image => image.id === selectedImageId)

    return <div className={mobileContainerClasses}>
        <ProductImage
            styleClasses={{
                imageClasses: styles.featuredImage,
                containerClasses: styles.imageContainerMobile
            }}
            {...selectedImageProps}
        />
        {/* Slider for thumbnails to choose */}
        <div className={styles.sliderContainer}>
            {thumbnails.map((thumbnail, i) => {
                const itemClasses = classNames(styles.sliderItem, {
                    [`${styles[`sliderItem-isActive`]}`]: thumbnail.id === selectedImageId
                })

                return <div
                    key={`${thumbnail.id}-thumbnail`}
                    className={itemClasses}
                    onClick={() => {
                        switchImage(thumbnail.id)
                    }}
                >
                    <ProductImage
                        {...thumbnail}
                        tag={''}
                        styleClasses={{
                            imageClasses: styles.sliderImage,
                            containerClasses: styles.sliderImageContainer
                        }}
                        dataSelector={`gallery-thumbnail-${i}`}
                    />
                </div>
            }
            )}
        </div>
    </div>
}

const Gallery = ({
    productCode,
    selectionDeps,
    productTag,
    closeHandler
}) => {
    const { useProductData, useProductState } = selectionDeps
    const { title, category, subcategory, url, ardadDescriptors } = useProductData(productCode)
    const isMobile = useIsMobile('mobile')

    const productState = useProductState(productCode, [])
    const desktopGalleryRef = useRef(null)

    useEffect(() => {
        const thresholds = [50, 75]
        const desktopGallery = desktopGalleryRef.current

        const pushDataLayerOnScroll = () => {
            const scrollPercentage = (desktopGallery.scrollTop / (desktopGallery.scrollHeight - desktopGallery.clientHeight)) * 100
            if (scrollPercentage >= thresholds[0]) {
                pushDataLayerEvent({
                    'event': 'galleryScroll',
                    'scrollThreshold': thresholds[0]
                })
                thresholds.shift()
            }
        }
        if (desktopGalleryRef) {
            desktopGallery.addEventListener('scroll', pushDataLayerOnScroll)

            return () => {
                desktopGallery.removeEventListener('scroll', pushDataLayerOnScroll)
            }
        }
    }, [])

    const desktopContainer = classNames(styles.desktopContainer, 'u-hidden--lg-down')


    // AB TEST EXP.FAVORITES.DIS-530
    const { isV1: isFavoriteIconEnabled } = useExperiment('EXP.FAVORITES.DIS-530')

    const { defaultImage: thumbnailImage } = getDetailTileArdadImages(ardadDescriptors, productState, '1-1')

    const favoriteBtnCloseHanlder = () => {
        isMobile && closeHandler()
    }

    let images = getDetailModalArdadImages(ardadDescriptors, productState, '3-2').map((imageData, index) => {
        const tag = index === 0 ? productTag : ''
        const showFavoriteBtn = Boolean(index === 0 && isFavoriteIconEnabled)
        return {
            id: `${productCode}-${index}`,
            tag,
            filename: imageData.name,
            ...(showFavoriteBtn && {
                favoriteIcon: <FavoriteIcon
                    productCode={productCode}
                    location="Detail Modal PLP"
                    title={{text: title, href: url }}
                    dataSelector={`${productCode}-favorite`}
                    category={category}
                    subCategory={subcategory}
                    thumbnailImage={thumbnailImage}
                    onClick={favoriteBtnCloseHanlder}
                />
            }),
            ...imageData
        }
    })

    let thumbnails = getDetailModalArdadImages(ardadDescriptors, productState, '1-1').map((imageData, index) => {
        return {
            id: `${productCode}-${index}`,
            filename: imageData.name,
            ...imageData
        }
    })

    return <>
        <div className={desktopContainer} ref={desktopGalleryRef}>
            {images.map((image) => (
                <ProductImage
                    key={`${image.id}-desktop`}
                    styleClasses={{
                        imageClasses: styles.featuredImage,
                        containerClasses: styles.imageContainerDesktop
                    }}
                    {...image}
                />)
            )}
        </div>
        <MobileGallery
            productCode={productCode}
            selectionDeps={selectionDeps}
            images={images}
            thumbnails={thumbnails}
        />
    </>
}

Gallery.propTypes = {
    productCode: PropTypes.string.isRequired,
    selectionDeps: PropTypes.object.isRequired,
    productTag: PropTypes.string
}

export default Gallery